//import _block from '../core/_schema/_block'
import photoSchema from '../core/_schema/photo'
import fileSchema from '../core/_schema/file'


const schema = {
  endpoint: '/mediateka_slides',
  resource: {
    definition: 'MediatekaSlide-mediatekaSlide:read',
    properties: {
      translations: {
        type: 'translation',
        properties: {
          title: {
            //validate: ['required'],
          },
          alt: {},
          
//          slug: {},
        },
      },
      photoAuthor: {
        type: 'string',
        fullWidth: true
      },
      vimeoUrl: {
        type: 'string',
        fullWidth: true
      },
      type: {
        type: 'choice',
        choices: {
          video: 'video',
          photo: 'photo',
        },
        validate: ['required'],
      },
      importId: {
        type: 'number',
        fullWidth: false,
        description: {
          text: 'importId',
        },
      },
      photo: {
        type: 'image',
        description: 'T_GENERAL_PHOTO',
        endpoint: photoSchema.endpoint.single,
        //endpointMultiple: photoSchema.endpoint.multiple,
        thumbs: {
          endpoint: '/mediateka_slide_thumbs',
        },
        validate: ['maxSize'],
      },
    },
  },
}

export default schema
